import React from 'react'
import coursereport from "../pics/BestOnlineBadge2020.png"
import switchup from "../pics/switchup2020.png"
import revStarPart from "../pics/revStarPart.png"
import revStar from "../pics/revStar.png"

export default function Badges({ activeLinks = true }) {

	const stars = [1, 2, 3, 4, 5]
	const starPart = <img src={revStarPart} alt='star' style={{ 'margin':'0','vertical-align': 'baseline', 'height': '.8em' }} />
	const star = <img src={revStar} alt='star' style={{ 'margin':'0','vertical-align': 'baseline', 'height': '.8em' }} />

	return activeLinks ? (
		<div className="flexOneRow" style={{ 'width': '100%', 'paddingBottom': '1em', 'flexWrap': 'wrap' }}>
			<a target='_blank' href="https://www.coursereport.com/schools/barcelona-code-school#/reviews">
				<img
					className="badge"
					style={{
						// maxHeight: "300px",
						// maxWidth: "200px",
						display: "block",
						margin: "1em auto",
						flex: '1 1 250px'
					}}
					src={coursereport}
					alt="barcelona code school reviews on coursereport.com"
				/>
			</a>
			<a target='_blank' href="https://www.switchup.org/bootcamps/barcelona-code-school">
				<img
					className="badge"
					style={{
						// maxHeight: "300px",
						// maxWidth: "200px",
						display: "block",
						margin: "1em auto",
						flex: '1 1 250px'
					}}
					src={switchup}
					alt="barcelona code school reviews on switchup"
				/>
			</a>
		</div>
	) : (
		<div className="flexOneRow" style={{ 'width': '100%', 'flexWrap': 'wrap' }}>
			<img
				className="badge"
				style={{
					maxHeight: "300px",
					maxWidth: "200px",
					display: "block",
					margin: "1em auto",
					flex: '1 1 250px'
				}}
				src={coursereport}
				alt="barcelona code school reviews on coursereport.com"
			/>
			<img
				className="badge"
				style={{
					maxHeight: "300px",
					maxWidth: "200px",
					display: "block",
					margin: "1em auto",
					flex: '1 1 250px'
				}}
				src={switchup}
				alt="barcelona code school reviews on switchup"
			/>
			<div className='whiteCard flex1200' style={styles.reviewsContainer}>
				<h3 style={styles.review}>Google Reviews: 4.8 {star}{star}{star}{star}{star}</h3>
				<h3 style={styles.review}>SwitchUp: 4.8 {star}{star}{star}{star}{star} </h3>
				<h3 style={styles.review}>CourseReport: 4.7 {star}{star}{star}{star}{starPart}</h3>
			</div>
		</div>
	)
}

const styles = {
	review: {
		display: 'inline-block',
		wordBreak: 'none',
		margin: '0',
		width:'fit-content',
		minWidth: 'max-content'
	},
	reviewsContainer: {
		'display': 'flex',
		'justifyContent': 'space-between',
		'flexWrap': 'wrap',
		'alignItems': 'center',
	}

}