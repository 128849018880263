import { Link } from 'gatsby';
// import Christine from '../pics/students/christine_chang.jpg';
// import Brie from '../pics/students/brie_frontpage.jpg';
// import Contacts from './contacts' ;
// import Layout from "../layouts/index.js";
// import Ting from '../pics/students/ting_profile.jpg';
// import Mike from '../pics/students/mike.jpg';
// import Ross from '../pics/students/ross_sq.jpg';
// import Diane from '../pics/students/diane_sq.jpg';
// import WallOfFame from '../pics/barcelonacodeschool_wall_of_fame_1500x500.jpeg'
import screenCode from "../pics/campus/Bootcamp-1.jpg"
import neuralNetwork from "../pics/DALL·E 2023-02-16 12.07.29 - visualize neural network in a digital art style.jpg"
import favicon from "../pics/bcs_white_128.png"
import React from "react"
import Helmet from "react-helmet"
import PartnersLogos from "../components/partnerLogos.js"
// import OtherPartnersLogos from "../components/otherPartners.js"
import uxBackground from "../pics/campus/ux_students_barcelona_code_school_1-5.jpg"
import gameDevBackground from '../pics/level_1.png'
import threeDModellingAndPrinting from '../pics/3d-modelling-printing-barcelona-code-school.jpg'
// import bootcampv2 from '../pics/IMG_8805.jpg'

import Badges from '../components/badges.js'
import SocialMediaLinks from '../components/socialMediaLinks'
// import cyberSecImg from '../pics/philipp-katzenberger-iIJrUoeRoCQ-unsplash.jpg'

const IndexPage = () => (
    <div className='flexContent'>
        <Helmet
            title="Barcelona Code School"
            meta={[
                {
                    name: "google-site-verification",
                    content: "f1-lCvkhhiZUpU_0DmAh8FA4AEqThbpBIMcmy5HCVPk",
                },
                {
                    name: "description",
                    content:
                        "JavaScript Full-Stack Bootcamp in Barcelona Code School. Intensive 9-week course to become a software developer. JavaScript, React, React Native, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
                },
                {
                    name: "keywords",
                    content:
                        "javascript bootcamp, web development bootcamp, barcelona code school, react bootcamp, coding bootcamp in barcelona,become a developer, become a software engineer, become a UX designer",
                },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1",
                },
                {
                    property: "og:title",
                    content:
                        "Barcelona Code School - JavaScript Full-Stack Bootcamp in Barcelona Code School - learn React, React Native, Node, Express, MongoDB",
                },
                {
                    property: "og:description",
                    content:
                        "JavaScript Full-Stack Bootcamp in Barcelona Code School. Intensive 9-week course to become a developer. Teaching JavaScript, React, React Native, Node, Express, MongoDB, Git, HTML, CSS and all the hands-on skills and techniques needed to build web or native mobile apps.",
                },
                {
                    property: "og:image",
                    content:
                        "https://barcelonacodeschool.com/files/pics/barcelonacodeschool_wall_of_fame_1500x500.jpeg",
                },
                {
                    property: "og:url",
                    content: "https://barcelonacodeschool.com/",
                },
                { name: "twitter:card", content: "summary_large_image" },
            ]}
            link={[
                {
                    rel: "icon",
                    type: "image/x-icon",
                    href: `${favicon}`,
                },
            ]}
        />

        <h2 className='transparentContainer flexFullWidth'>Where do you want to go?</h2>

        <article className="transparentContainer flex450">
            <h3 className="">JavaScript Full-Stack Bootcamp</h3>
            <img src={screenCode} className='banners' alt='computer display with code' />
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>I want to learn programming to be able to <span className="heavy highlighter">build any kind of websites/web applications or mobile applications for iOS and Android</span></li>
                    <li>I want to change my career and <span className="heavy highlighter">land a job with one of Barcelona Code School's hiring partners</span>.</li>
                    <li>I want to be able to <span className='heavy highlighter'>work remotely from anywhere in the world</span> for a tech company.</li>
                    <li>I want to build apps for millions of people to use.</li>
                    <li>I'm thinking of <span className='heavy highlighter'>becoming a freelancer or starting my own software development studio</span> taking clients when I want to.</li>
                    <li>I have this <span className='heavy highlighter'>idea for the app and I really want to build it</span> by myself!</li>
                </ul>
                <h4 className="heavy">Recommended tracks:</h4>
                <h2 className='inverted'>full-time in-person <Link to="/barcelona-code-school/javascript-full-stack-bootcamp/">JavaScript Full-Stack Bootcamp</Link></h2>
                <h2 className='inverted'><Link to="/barcelona-code-school/javascript-full-stack-online-bootcamp/">
                    JavaScript Full-Stack Online Mentored Bootcamp</Link></h2>
            </div>
        </article>

        <article className="transparentContainer flex450">
            <h3 className="">UX/UI Design Bootcamp</h3>
            <img src={uxBackground} className='banners' alt='user experience design students' />
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>I want to know how to <span className='heavy highlighter'>create clear and intuitive User Experience designs</span> for digital products enjoyed by miliions of people.</li>
                    <li>I want to understand users pains and needs to <span className='heavy highlighter'>make their life easier through my products</span>.</li>
                    <li>I want to design <span className='heavy highlighter'>beautiful user interfaces</span> loved by the users.</li>
                    <li>I want to <span className='heavy highlighter'>work in tech's most creative area</span> of User Experience and user interface design.</li>
                    <li>I want to <span className='heavy highlighter'>start my career in one of the fastest growing fields</span> with huge demand for the UX professionals.</li>
                    <li>I might become a <span className='heavy highlighter'>freelancer or User Experience consultant</span> and being my own boss.</li>
                </ul>
                <h4 className="heavy">Recommended track:</h4>
                <h2 className='inverted'><Link to="/ux-design-bootcamp-in-barcelona-code-school/">UX/UI Design Bootcamp</Link></h2>
                <h2 className='inverted'><Link to="/ux-design-online-mentored-bootcamp/">UX/UI Design Online Mentored Bootcamp / self-paced</Link></h2>
            </div>
        </article>


        <article className="transparentContainer flex450">
            <h3 className="">Deep Learning and AI Engineering Bootcamp</h3>
            <figure>
                <img src={neuralNetwork} alt="visualization of neural network in a digital art style by openAI" className='banners' />
                <figcaption className='imgCaptionInner'>Neural Network visualized by OpenAI</figcaption>
            </figure>
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>I want to learn Machine Learning, Deep Learning, Neural Networks and understand how Artificial Intelligence works</li>
                    <li>I want to change my career and <span className="heavy highlighter">land a job in AI and Deep Learning</span>.</li>
                    <li>I want to be able to <span className='heavy highlighter'>work in the fastest-growing cuttin-edge area of IT</span>.</li>
                    <li>I want to build AIs for millions of people to use.</li>
                    <li>I have an <span className='heavy highlighter'>idea for the AI app and I really want to build it</span>!</li>
                </ul>
                <h4 className="heavy">Recommended track:</h4>
                <h2 className='inverted'><Link to="/machine-learning-and-ai-engineering-bootcamp/">Deep Learning and Artificial Intelligence Engineering Bootcamp</Link></h2>
            </div>
        </article>

        {/*    <article className="transparentContainer flex450">
    <h3 className="">Advanced Web Development Bootcamp</h3>
    <img src={bootcampv2} className='banners' alt='user experience design students'/>
    <div className='whiteCard'>
    <ul className='neonList'>
    <li>I already know how to build full-stack web application and <span className='heavy highlighter'>want to learn advanced tools and techniques</span>.</li>
    <li>I finished a coding bootcamp and am willing to go deeper into more challenging topics.</li>
    <li>I want to increase my value as a developer.</li>
    <li>I want to learn <span className='heavy highlighter'>TypeScript</span>, <span className='heavy highlighter'>TDD</span>, <span className='heavy highlighter'>Redux</span>, <span className='heavy highlighter'>Next.js</span>, <span className='heavy highlighter'>Server-Side Rendering</span>, <span className='heavy highlighter'>Docker</span>, <span className='heavy highlighter'>WebSocket</span>, <span className='heavy highlighter'>AWS</span>.</li>
    </ul>
    <h4 className="heavy">Recommended track:</h4>
    <h2 className='inverted'><Link to="/advanced-web-develoment-bootcamp/">Advanced Web Development Bootcamp</Link></h2>
    </div>
    </article>*/}


        <article className="transparentContainer flex450">
            <h3 className="">Game Develoment Bootcamp</h3>
            <img src={gameDevBackground} className='banners' alt='game development bootcamp in barcelona' />
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>I want to know how to build <span className='heavy highlighter'>2D and 3D games</span> of different genres.</li>
                    <li>I want to learn <span className='heavy highlighter'>Unity</span> which will let me learn any other engine and editor in the future.</li>
                    <li>I want to become profficient with the default language of game development – <span className='heavy highlighter'>C#</span>.</li>
                    <li>I want to increase my value by learning <span className='heavy highlighter'>storytelling, game design, UX and UI</span> for game development.</li>
                    <li>I want to <span className='heavy highlighter'>start my career</span> with one of game development studios or go indie!</li>
                </ul>
                <h4 className="heavy">Recommended tracks:</h4>
                <h2 className='inverted'><Link to="/game-development-bootcamp/">Game Development in-person Bootcamp</Link></h2>
            </div>
        </article>



        <article className="transparentContainer flex450">
            <h3 className="">3D Modelling and Printing</h3>
            <img src={threeDModellingAndPrinting} className='banners' alt='3d modelling and printing course in barcelona' />
            <div className='whiteCard'>
                <ul className='neonList'>
                    <li>I want to know how to design <span className='heavy highlighter'>3D models</span>.</li>
                    <li>I want to learn <span className='heavy highlighter'>Blender</span>.</li>
                    <li>I want to know <span className='heavy highlighter'>how to prepare 3D models for print</span>.</li>
                    <li>I want to <span className='heavy highlighter'>print my models</span> and take them home.</li>
                </ul>
                <h4 className="heavy">Recommended tracks:</h4>
                <h2 className='inverted'><Link to="/3d-modelling-and-printing/">3D Modelling and Printing</Link></h2>
            </div>
        </article>










        <h2 className='transparentContainer flexFullWidth'>Let's talk about your future</h2>
        <article className="transparentContainer flex450">
            <div className="whiteCard">

                <a href="https://zcal.co/i/c9mEbhSA" target='_blank'>
                    <button className='heavy' style={{ width: "auto", "margin": '1em 0' }}>
                        Book a meeting/call with us
                    </button>
                </a>

                <p>
                    <a
                        href="mailto:office@barcelonacodeschool.com"
                        className="highlighter"
                    >
                        Send an email
                    </a>
                </p>
                <p>
                    <a
                        href="https://goo.gl/maps/ZK4iayVREQX4jprs6"
                        target="blank"
                    >
                        Carrer de Paris 157, 08036, Barcelona, Spain
                    </a>
                </p>
                <SocialMediaLinks />
                <p>
                    <a href="tel:+34936639807">+34 936 639 807</a>
                </p>
            </div>
        </article>



        {/* <article className='transparentContainer flex450'>
    <div className="video-container offsetImage">
    <iframe
    width="560"
    height="315"
    src="https://www.youtube.com/embed/2yCjj35RZFQ?rel=0&modestbranding=1"
    frameBorder="0"
    allowFullScreen
    ></iframe>
    </div>
    </article> */}

        <article className='transparentContainer flex450'>
            <video src={'https://barcelonacodeschool.com/files/videos/BCS_STUDENTS_ALLSTAR.mp4'} width="100%" height="100%" controls autoPlay={false} muted={false} type="video/mp4" />
        </article>




            <section className='transparentContainer flexFullWidth'>

        <h2 >We teach better taking you from zero to a job-ready level 🏆</h2>
        <Badges activeLinks={false} />
            </section>



        <article className="transparentContainer flex225">
            <h3>Small groups</h3>
            <div className="whiteCard">
                <p>
                    We say <span className="heavy">small</span> and we
                    mean it. With <span className="heavy">
                        maximum 6 students per group</span> and instructors being on campus all day it's guaranteed to give you the <span className="heavy">
                        best students/instructors ratio ever
                    </span>.</p>
                <p>Thanks to that we can make sure that everybody
                    gets help and support which is crucial for moving
                    forward in a fast paced learning process.
                </p>
            </div>
        </article>

        <article className="transparentContainer flex225">
            <h3>Outstanding curriculum</h3>
            <div className="whiteCard">
                <p>
                    We teach <span className='heavy'>web and mobile development</span> with JavaScript, Node, Express, React.js, React
                    Native, MongoDB. It's the <span className='heavy'>most in-demand stack on the job market</span>.</p>
                <p>The curriculum is being constantly
                    updated and improved based on latest tendencies and
                    job market demand. We only teach skills you will need
                    to use on a daily basis and we go all the way into
                    it.
                </p>
            </div>
        </article>


        <article className="transparentContainer flex225">
            <h3>We know how to teach</h3>
            <div className="whiteCard">
                <p>
                    Our instructors are not only <span className='heavy'>highly qualified
                        developers</span> but also <span className='heavy'>skillful mentors</span> who went
                    through the bootcamps in the past and
                    experienced this from the students perspective as
                    well.</p>
                <p>We use unique student-driven
                    approach adapting to each student's needs and abilities leaving nobody behind.
                </p>
            </div>
        </article>


        <article className="transparentContainer flex225">
            <h3>Real job support</h3>
            <div className="whiteCard">
                <p>All the practical work we do during the bootcamp is as close to the actual working environment as possible so that you will know what it's going to be like at your new job and get prepared for that.</p>
                <p>You will create your portfolio with the projects done during the bootcamp to have feasible deliverables to present to the recruiters and prove your skills.</p>
                <p>Combined with the CV councelling, job markets insights and job support through our hiring partners you are set on track to launch your new career right after the graduation!</p>
            </div>
        </article>







        <section className='flexContent'>

            <h2 className='transparentContainer flexFullWidth spanAcrossGrid' id="graduatesBrief">We've helped hundreds of graduates to launch career in tech 🏅</h2>

            <article className='transparentContainer flex225'>
                <div className="whiteCard">
                    <img className='offsetImage' src='https://barcelonacodeschool.com/blog-pics/2021/aboutme.c2f4f8be.jpg' alt='Yen Hoang' />
                    <p className='bisonText'>Yen Hoang</p>
                    <p>Nail salon owner → full-time developer</p>
                </div>
            </article>

            <article className='transparentContainer flex225'>
                <div className="whiteCard">
                    <img className='offsetImage' src='https://barcelonacodeschool.com/blog-pics/2020/anna_chew.jpg' alt='Anna Chew' />
                    <p className='bisonText'>Anna Chew</p>
                    <p>Hospitality → full-time developer</p>
                </div>
            </article>

            <article className='transparentContainer flex225'>
                <div className="whiteCard">
                    <img className='offsetImage' src='https://barcelonacodeschool.com/blog-pics/2022/anna_veerman.jpeg' alt='Anna Veerman' />
                    <p className='bisonText'>Anna Veerman</p>
                    <p>Teacher → full-time UX designer</p>
                </div>
            </article>

            <article className='transparentContainer flex225'>
                <div className="whiteCard">
                    <img className='offsetImage' src='https://barcelonacodeschool.com/blog-pics/2022/daniela_diesel.jpeg' alt='Daniela Diesel' />
                    <p className='bisonText'>Daniela Ocklind Diesel</p>
                    <p>Real estate agent → full-time UX designer</p>
                </div>
            </article>
        </section>













        <section className='flexContent'>

            <h2 className='transparentContainer flexFullWidth ' id="chooseYourWay">Explore our courses 🔎</h2>

            <article className='transparentContainer flex225'>
                <h2 className='highlighter'>Bootcamps</h2>
                <div className="whiteCard">
                    <Link to="/barcelona-code-school/javascript-full-stack-bootcamp/">
                        <h3>JavaScript Full-Stack In-Person Bootcamp / 9
                            weeks</h3>
                    </Link>

                    <Link to="/barcelona-code-school/javascript-full-stack-online-bootcamp/">
                        <h3>JavaScript Full-Stack Online Mentored Bootcamp / from 9
                            weeks</h3>
                    </Link>

                    <Link to="/ux-design-bootcamp-in-barcelona-code-school/">
                        <h3>UX/UI Design In-person Bootcamp / 9 weeks</h3>
                    </Link>

                    <Link to="/ux-design-online-mentored-bootcamp/">
                        <h3>UX/UI Design Online Mentored Bootcamp / self-paced</h3>
                    </Link>

                    <Link to="/machine-learning-and-ai-engineering-bootcamp/">
                        <h3>Deep Learning and AI Engineering Online Mentored Bootcamp / 13
                            weeks</h3>
                    </Link>

                    {/*    <Link to="/advanced-web-develoment-bootcamp/">
    <h3>Advanced Web Development Online Mentored Bootcamp</h3>
    </Link>*/}

                    <Link to="/game-development-bootcamp/">
                        <h3>Game Development in-person Bootcamp / 9 weeks</h3>
                    </Link>

                </div>
            </article>

            <article className='transparentContainer flex225'>
                <h2 id='shortCourses' className='highlighter'>Short in-person courses</h2>
                <div className="whiteCard">

                    <Link to="/programming-with-javascript/">
                        <h3>Programming with JavaScript / 2 weeks</h3>
                    </Link>


                    <Link to="/responsive-web-design-with-css3/">
                        <h3>Responsive Web Design / 1 week</h3>
                    </Link>


                    <Link to="/reactjs-crash-course/">
                        <h3>React.js Crash Course / 1 week</h3>
                    </Link>


                    <Link to="/backend-development-with-node-express-mongodb/">
                        <h3>Back-end Development with Node, Express, MongoDB
                            / 1 week</h3>
                    </Link>

                    <Link to="/cyber-security-for-web-developers/">
                        <h3>Cyber Security for Web Developers</h3>
                    </Link>

                </div>
            </article>

            <article className='transparentContainer flex225'>
                <h2 id='shortOnlineCourses' className='highlighter'>Udemy Short courses</h2>
                <div className="whiteCard">

                    <a
                        href="https://www.udemy.com/course/html-and-css-for-the-total-newbies/?referralCode=3463E1667BB1FD9D016C"
                        target='_blank'
                        rel='noreferrer noopeners'
                    >
                        <h3>HTML & CSS for the total newbies</h3>
                    </a>

                    <a
                        href="https://www.udemy.com/course/programming-with-python-bcs/?referralCode=9B251D06407ACC8D5DBC"
                        target='_blank'
                        rel='noreferrer noopeners'
                    >
                        <h3>Programming with Python</h3>
                    </a>
                    <a
                        href="https://www.udemy.com/course/real-time-apps-with-socket-io-react-and-express/?referralCode=08B75162BEC2699422FF"
                        target='_blank'
                        rel='noreferrer noopeners'
                    >
                        <h3>Real-time apps with Socket.IO</h3>
                    </a>
                    <a
                        href="https://www.udemy.com/course/sending-emails-with-nodemailer-gmail-and-oauth2/?referralCode=53A07520999CD0870D6B"
                        target='_blank'
                        rel='noreferrer noopeners'
                    >
                        <h3>Nodemailer, Gmail and OAuth2</h3>
                    </a>

                    {/* <Link to="/online-short-courses/responsive-web-design-with-css3-online/">
                        <h3>Responsive web design with CSS3 online course /
                            2 weeks</h3>
                    </Link>


                    <Link to="/online-short-courses/programming-with-javascript-online-course/">
                        <h3>Programming with JavaScript online course / 4
                            weeks</h3>
                    </Link>


                    <Link to="/online-short-courses/react-crash-course-online/">
                        <h3>React.js Online Crash Course / 2 weeks</h3>
                    </Link>


                    <Link to="/online-short-courses/backend-development-with-node-express-mongodb-online/">
                        <h3>Back-end Development with Node, Express, MongoDB
                            Online course / 2 weeks</h3>
                    </Link>


                    <Link to="/programming-with-python/">
                        <h3>Programming with Python self-learning course</h3>
                    </Link> */}

                </div>
            </article>

            <article className='transparentContainer flex225'>
                <h2 className='highlighter'>Corporate</h2>
                <div className="whiteCard">

                    <h3>Hire developer or designer</h3>
                    <p className='neonText'>Become our hiring partner and <Link
                        to="/job-offer/"
                    >be the first to receive profiles</Link> of freshly made
                        junior developers or designers – graduates of our bootcamps</p>

                    <h3>Training your team</h3>
                    <p className='neonText'>
                        <Link to="/corporate-training/">
                            Custom courses tailored for your needs
                        </Link>.</p>

                    <h3>Testing candidates</h3>
                    <p className='neonText'>
                        <Link to="/corporate-training/">
                            Technical tests for your developers hiring
                            process
                        </Link>.</p>
                </div>
            </article>
        </section>




        <section className="transparentContainer flex600">
            <div>
                <h2>Some of the companies where our graduates work or worked</h2>
                <PartnersLogos />
            </div>
        </section>

        {/*    <section className="transparentContainer flex300">
    <div>
    <h2>Our partners</h2>
    <OtherPartnersLogos />
    </div>
    </section>*/}
    </div>


)

export default IndexPage
